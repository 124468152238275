export const danishCities = [
  'Aabenraa',
  'Aabybro',
  'Aakirkeby',
  'Aalborg',
  'Aarhus',
  'Aars',
  'Aarsballe',
  'Agerbæk',
  'Agerskov',
  'Agersted',
  'Agersø',
  'Agger',
  'Agri',
  'Ahl',
  'Aistrup',
  'Albertslund',
  'Alken',
  'Aller',
  'Allerup',
  'Allinge',
  'Allinge-Sandvig',
  'Allingåbro',
  'Almind',
  'Als',
  'Alslev',
  'Alsted',
  'Asnæs',
  'Assens',
  'Augustenborg',
  'Aulum',
  'Auning',
  'Ballerup',
  'Balling',
  'Billund',
  'Bindslev',
  'Birkerød',
  'Bjerringbro',
  'Blokhus',
  'Bogense',
  'Bork Havn',
  'Borre',
  'Brande',
  'Bredballe',
  'Bredsten',
  'Brovst',
  'Brøndby',
  'Brønderslev',
  'Bælum',
  'Charlottenlund',
  'Christiansfeld',
  'Dragør',
  'Dronninglund',
  'Dybvad',
  'Ebeltoft',
  'Ejby',
  'Ejstrupholm',
  'Esbjerg',
  'Eskebjerg',
  'Espergærde',
  'Faaborg',
  'Farsø',
  'Farum',
  'Faxe',
  'Fensmark',
  'Fjenneslev',
  'Fjerritslev',
  'Fredericia',
  'Frederikshavn',
  'Frederikssund',
  'Frederiksværk',
  'Fårvang',
  'Galten',
  'Gedser',
  'Gentofte',
  'Gilleleje',
  'Gistrup',
  'Give',
  'Gladsaxe',
  'Glesborg',
  'Glostrup',
  'Grenaa',
  'Greve Strand',
  'Grindsted',
  'Gråsten',
  'Græsted',
  'Gudme',
  'Gørlev',
  'Haderslev',
  'Hadsund',
  'Hammel',
  'Hanstholm',
  'Harboøre',
  'Hasle',
  'Haslev',
  'Haverslev',
  'Havnsø',
  'Hedehusene',
  'Hejnsvig',
  'Hellebæk',
  'Helsinge',
  'Helsingør',
  'Herlev',
  'Herlufmagle',
  'Hillerød',
  'Hinnerup',
  'Hirtshals',
  'Hjarbæk',
  'Hjørring',
  'Hobro',
  'Holbæk',
  'Holeby',
  'Holstebro',
  'Hornbæk',
  'Horsens',
  'Humble',
  'Humlebæk',
  'Hundested',
  'Hurup Thy',
  'Hvalsø',
  'Hvide Sande',
  'Hvidovre',
  'Hyllinge',
  'Høje Taastrup',
  'Højmark',
  'Højslev',
  'Høng',
  'Hørning',
  'Hørsholm',
  'Hørve',
  'Ishøj',
  'Jebjerg',
  'Jelling',
  'Jerslev',
  'Juelsminde',
  'Jyderup',
  'Jægerspris',
  'Kalundborg',
  'Karise',
  'Karup',
  'Kastrup',
  'Kerteminde',
  'Kibæk',
  'Kirke Hyllinge',
  'Klemensker',
  'Klitmøller',
  'Klovborg',
  'Kolding',
  'Kongensbro',
  'Korsgade',
  'Korsør',
  'København',
  'Køge',
  'Langeskov',
  'Langå',
  'Lejre',
  'Lem',
  'Lemvig',
  'Lendum',
  'Lillerød',
  'Lunderskov',
  'Lyngby',
  'Løgstrup',
  'Løgstør',
  'Løkken',
  'Mariager',
  'Maribo',
  'Middelfart',
  'Mårslet',
  'Nakskov',
  'Nexø',
  'Nibe',
  'Nim',
  'Nivå',
  'Nordborg',
  'Nordby',
  'Nyborg',
  'Nykøbing Falster',
  'Nykøbing Mors',
  'Næstved',
  'Nørager',
  'Nørre Aaby',
  'Nørre Nebel',
  'Nørresundby',
  'Odder',
  'Odense',
  'Oksbøl',
  'Osted',
  'Otterup',
  'Padborg',
  'Pandrup',
  'Præstø',
  'Ramløse',
  'Randbøldal',
  'Randers',
  'Ranum',
  'Ribe',
  'Ringsted',
  'Roskilde',
  'Rudkøbing',
  'Ry',
  'Ryslinge',
  'Rødding',
  'Rødekro',
  'Rødovre',
  'Rønne',
  'Rønne',
  'Rønnede',
  'Sabro',
  'Sakskøbing',
  'Sejlflod',
  'Silkeborg',
  'Sindal',
  'Skanderborg',
  'Skibbild',
  'Skibby',
  'Skive',
  'Skjern',
  'Skælskør',
  'Skærbæk',
  'Skødstrup',
  'Slagelse',
  'Slangerup',
  'Snekkersten',
  'Solrød Strand',
  'Sorø',
  'Spøttrup',
  'Starup',
  'Stenlille',
  'Stenløse',
  'Stokkemarke',
  'Store Heddinge',
  'Struer',
  'Strøby Egede',
  'Stubbekøbing',
  'Støvring',
  'Sunds',
  'Svaneke',
  'Svendborg',
  'Svenstrup',
  'Svinninge',
  'Sæby',
  'Sønderborg',
  'Taastrup',
  'Tarm',
  'Terndrup',
  'Them',
  'Thisted',
  'Thyborøn',
  'Tinglev',
  'Tisvildeleje',
  'Tjele',
  'Toftlund',
  'Tranbjerg',
  'Trustrup',
  'Tune',
  'Tuse',
  'Tårnby',
  'Tårs',
  'Tønder',
  'Ulfborg',
  'Vamdrup',
  'Vandel',
  'Varde',
  'Vejby',
  'Vejle',
  'Vester Hassing',
  'Vestervig',
  'Viborg',
  'Viby',
  'Vig',
  'Vildbjerg',
  'Vipperød',
  'Vojens',
  'Vordingborg',
  'Åbyhøj',
  'Ålsgårde',
  'Ølgod',
  'Ølsted',
  'Ølstykke-Stenløse',
  'Ørbæk',
  'Ørnhøj',
  'Østbirk',
  'Øster Assels',
  'Øster Hurup',
  'Østermarie',
];