import * as React from 'react';
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useAuth from "../../helpers/authentication.js";

import { validateStrLength, validateNumberRange } from '../../helpers/validate';
import { danishCities } from '../../static/cities.js'

import FloorPlanPlaceholder from "../../static/floor_plan_placeholder.png";

export default function CreateListing() {
  const {t} = useTranslation("common");

  const { getAccessTokenSilently } = useAuth();
  
  const [expanded, setExpanded] = React.useState('generalPanel');

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const [listingType, setListingType] = React.useState("apartment");
  const [description, setDescription] = React.useState("");
  const [energyLabel, setEnergyLabel] = React.useState("A2015");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState(danishCities[0]);
  const [postalCode, setPostalCode] = React.useState(1000);
  const [rentalPeriodType, setRentalPeriodType] = React.useState("unlimited");
  const [moveInDate, setMoveInDate] = React.useState(dayjs());
  const [moveOutDate, setMoveOutDate] = React.useState(dayjs().add(1, 'day'));
  const [currency, setCurrency] = React.useState("DKK");
  const [monthlyRent, setMonthlyRent] = React.useState(5000);
  const [monthlyHeatingAccount, setMonthlyHeatingAccount] = React.useState(500);
  const [monthlyWaterAccount, setMonthlyWaterAccount] = React.useState(500);
  const [prepaidRent, setPrepaidRent] = React.useState(15000);
  const [deposit, setDeposit] = React.useState(20000);
  const [size, setSize] = React.useState(50);
  const [rooms, setRooms] = React.useState(3);
  const [other, setOther] = React.useState({
    balcony: false, 
    garden: false,
    pets: false,
    parking: false,
    chargingStation: false,
    elevator: false,
    storage: false,
    shareable: false,
    smoking: false,
    furnished: false,
    ageFriendly: false,
    washingMachine: false,
    dryerMachine: false,
    dishWasher: false,
  });
  const [images, setImages] = React.useState([]);
  const [floorPlan, setFloorPlan] = React.useState(null);

  const [descriptionError, setDescriptionError] = React.useState(false);
  const [addressError, setAddressError] = React.useState(false);
  const [postalCodeError, setPostalCodeError] = React.useState(false);
  const [moveOutDateError, setMoveOutDateError] = React.useState(false);
  const [monthlyRentError, setMonthlyRentError] = React.useState(false);
  const [monthlyHeatingAccountError, setMonthlyHeatingAccountError] = React.useState(false);
  const [monthlyWaterAccountError, setMonthlyWaterAccountError] = React.useState(false);
  const [prepaidRentError, setPrepaidRentError] = React.useState(false);
  const [depositError, setDepositError] = React.useState(false);
  const [sizeError, setSizeError] = React.useState(false);
  const [roomsError, setRoomsError] = React.useState(false);
  
  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  var displayLabels = [t("dashboard.createListing.other.balcony"), t("dashboard.createListing.other.garden"), t("dashboard.createListing.other.pets"), t("dashboard.createListing.other.parking"), t("dashboard.createListing.other.chargingStation"), t("dashboard.createListing.other.elevator"), t("dashboard.createListing.other.storage"), t("dashboard.createListing.other.shareable"), t("dashboard.createListing.other.smoking"), t("dashboard.createListing.other.furnished"), t("dashboard.createListing.other.ageFriendly"), t("dashboard.createListing.other.washingmachine"), t("dashboard.createListing.other.dryermachine"), t("dashboard.createListing.other.dishwasher")];
  var checkboxInitObj = {};

  Object.entries(other).forEach(([key, value], index) => {
    checkboxInitObj[key] = {'value': value, 'label': displayLabels[index]};
  });

  const handleOtherChange = (key, event) => {
    let objClone = { ...other };
    objClone[key] = event.target.checked
    setOther(objClone);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let base64Images = [];
    for (let i = 0; i < images.length; i++) {
      base64Images.push(await convertBase64(images[i]));
    };

    let base64FloorPlan = null;
    if (floorPlan !== null) {
      base64FloorPlan = await convertBase64(floorPlan);
    } else {
      base64FloorPlan = FloorPlanPlaceholder;
    }

    var tempMoveOutDate = null
    if (rentalPeriodType === 'limited') {
      tempMoveOutDate = moveOutDate.format('MM/DD/YYYY').toString();
    }
    await fetch('/create-listing', {
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${await getAccessTokenSilently()}`}, 
        body: JSON.stringify({
          "listingType": listingType,
          "description": description,
          "energyLabel": energyLabel,
          "address": address,
          "city": city, 
          "postalCode": postalCode,
          "rentalPeriodType": rentalPeriodType,
          "moveInDate": moveInDate.format('MM/DD/YYYY').toString(),
          "moveOutDate": moveOutDate, 
          "currency": currency,
          "monthlyRent": monthlyRent, 
          "monthlyHeatingAccount": monthlyHeatingAccount, 
          "monthlyWaterAccount": monthlyWaterAccount, 
          "prepaidRent": prepaidRent,
          "deposit": deposit, 
          "size": size, 
          "rooms": rooms, 
          ...other,
          "images": base64Images,
          "floorPlan": base64FloorPlan,
        })
      }).then((res) =>
      res.json().then(() => {
        setOpenSuccess(true);
      }).catch(() => {
        setOpenError(true);
      })
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }} 
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Grow direction="up" in={true} mountOnEnter unmountOnExit>
          <Box sx={{ 
              minWidth: { xs: 300, md: 660 },
              maxWidth: { xs: 360, md: 860 },
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', 
            }}
          >
            <Typography variant='h5' sx={{ p: 2 }}>{t('dashboard.createListing.title')}</Typography>
            <Box>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                sx={{
                  minWidth: { xs: 300, md: 660 },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }} 
              >
                <Accordion defaultExpanded expanded={expanded === 'generalPanel'} onChange={handlePanelChange('generalPanel')} sx={{ width: '100%' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="general-panel-bh-content"
                    id="general-panel-bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      {t('dashboard.createListing.general.title')}
                    </Typography>
                  </AccordionSummary>
                  <Stack spacing={2} sx={{
                      mx: 5,
                      marginBottom: 5 
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="listing-type-select-label">{t('dashboard.createListing.listingType.label')}</InputLabel>
                      <Select
                        labelId="listing-type-select-label"
                        id="listing-type-select"
                        label={t('dashboard.createListing.listingType.label')}
                        value={listingType}
                        onChange={e => setListingType(e.target.value)}
                      >
                        <MenuItem value={"apartment"}>{t('dashboard.createListing.listingType.apartment')}</MenuItem>
                        <MenuItem value={"house"}>{t('dashboard.createListing.listingType.house')}</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <TextField 
                        type="text" 
                        id="description-input" 
                        label={t('dashboard.createListing.description.label')} 
                        value={description} 
                        error={descriptionError}
                        onChange={e => setDescription(e.target.value)} 
                        onBlur={() => setDescriptionError(validateStrLength(description, 1))}
                        variant="outlined" 
                        multiline
                        minRows={2}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField 
                        type="text" 
                        id="energy-label-input" 
                        label={t('dashboard.createListing.energyLabel.label')} 
                        value={energyLabel}
                        onChange={e => setEnergyLabel(e.target.value)} 
                        variant="outlined"
                      />
                    </FormControl>
                  </Stack>
                </Accordion>
                <Accordion expanded={expanded === 'rentalPeriodPanel'} onChange={handlePanelChange('rentalPeriodPanel')} sx={{ width: '100%' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="rental-period-panel-bh-content"
                    id="rental-period-panel-bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      {t('dashboard.createListing.rentalPeriod.title')}
                    </Typography>
                  </AccordionSummary>
                  <Stack spacing={2} sx={{
                      mx: 5,
                      marginBottom: 5 
                    }}
                  >
                    {moveOutDateError && (
                      <Alert severity="error">
                        <AlertTitle>{t('dashboard.error')}</AlertTitle>
                        {t('dashboard.createListing.rentalPeriod.error')}
                      </Alert>
                    )}
                    <FormControl fullWidth>
                      <InputLabel id="rental-period-type-select-label">{t('dashboard.createListing.rentalPeriod.type.label')}</InputLabel>
                      <Select
                        labelId="rental-period-type-select-label"
                        id="rental-period-type-select"
                        label={t('dashboard.createListing.rentalPeriod.type.label')}
                        value={rentalPeriodType}
                        onChange={e => setRentalPeriodType(e.target.value)}
                      >
                        <MenuItem value={"unlimited"}>{t('dashboard.createListing.rentalPeriod.type.unlimited')}</MenuItem>
                        <MenuItem value={"limited"}>{t('dashboard.createListing.rentalPeriod.type.limited')}</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                          id="move-in-date-picker" 
                          label={t('dashboard.createListing.rentalPeriod.moveInDate.label')} 
                          value={moveInDate} 
                          onChange={(newValue) => setMoveInDate(newValue)} 
                        />
                      </LocalizationProvider>
                    </FormControl>
                    {rentalPeriodType === 'limited' && (
                      <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker 
                            id="move-out-date-picker" 
                            label={t('dashboard.createListing.rentalPeriod.moveOutDate.label')}
                            value={moveOutDate} 
                            onChange={(newValue) => {setMoveOutDate(newValue);setMoveOutDateError(dayjs(newValue).isBefore(dayjs()))}} 
                            textField={(params) => <TextField 
                              {...params} sx={{ width:"100%" }} 
                              error={moveOutDateError}
                            />} 
                          />
                        </LocalizationProvider>
                      </FormControl>
                    )}
                  </Stack>
                </Accordion>
                <Accordion expanded={expanded === 'locationPanel'} onChange={handlePanelChange('locationPanel')} sx={{ width: '100%' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="location-panel-bh-content"
                    id="location-panel-bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      {t('dashboard.createListing.location.title')}
                    </Typography>
                  </AccordionSummary>
                  <Stack spacing={2} sx={{
                      mx: 5,
                      marginBottom: 5 
                    }}
                  >
                    <FormControl>
                      <TextField
                        type="address" 
                        id="address-input" 
                        label={t('dashboard.createListing.location.address.label')} 
                        value={address} 
                        error={addressError} 
                        onChange={e => setAddress(e.target.value)} 
                        onBlur={() => setAddressError(validateStrLength(address, 1))} 
                        variant="outlined" 
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="city-select-label">{t('dashboard.createListing.location.city.label')}</InputLabel>
                      <Select
                        labelId="city-select-label"
                        id="city-select"
                        label={t('dashboard.createListing.location.city.label')}
                        value={city}
                        onChange={e => setCity(e.target.value)}
                      >
                        {danishCities.map((city) => (
                          <MenuItem value={city}>{city}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <TextField 
                        type="number" 
                        id="postal-code-input" 
                        label={t('dashboard.createListing.location.postalCode.label')}
                        value={postalCode} 
                        error={postalCodeError}
                        onChange={e => setPostalCode(e.target.value)} 
                        onBlur={() => setPostalCodeError(validateNumberRange(postalCode, 0))}
                        variant="outlined" 
                      />
                    </FormControl>
                    {/* <FormControl>
                      <TextField 
                        type="number" 
                        id="center-dist-input" 
                        label="Distance from city center [m]" 
                        value={centerDist} 
                        error={centerDistError}
                        onChange={e => setCenterDist(e.target.value)} 
                        onBlur={() => setCenterDistError(validateNumberRange(centerDist, 0))}
                        variant="outlined" 
                      />
                    </FormControl> */}
                  </Stack>
                </Accordion>
                <Accordion expanded={expanded === 'pricePanel'} onChange={handlePanelChange('pricePanel')} sx={{ width: '100%' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="price-panel-bh-content"
                    id="price-panel-bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      {t('dashboard.createListing.price.title')}
                    </Typography>
                  </AccordionSummary>
                  <Stack spacing={2} sx={{
                      mx: 5,
                      marginBottom: 5 
                    }}
                  >
                    <FormControl>
                      <InputLabel id="currency-select-label">{t('dashboard.createListing.price.currency.label')}</InputLabel>
                      <Select
                        labelId='currency-select-label'
                        id="currency-select"
                        value={currency}
                        onChange={e => setCurrency(e.target.value)}
                        label={t('dashboard.createListing.price.currency.label')}
                      >
                        <MenuItem value="DKK">DKK</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <TextField 
                        type="number" 
                        id="monthly-rent-input" 
                        label={t('dashboard.createListing.price.rent.label')} 
                        value={monthlyRent} 
                        error={monthlyRentError}
                        onChange={e => setMonthlyRent(e.target.value)} 
                        onBlur={() => setMonthlyRentError(validateNumberRange(monthlyRent, 0))}
                        variant="outlined" 
                      />
                    </FormControl>
                    <FormControl>
                      <TextField 
                        type="number" 
                        id="monthly-heating-account-input" 
                        label={t('dashboard.createListing.price.heatingAccount.label')} 
                        value={monthlyHeatingAccount} 
                        error={monthlyHeatingAccountError}
                        onChange={e => setMonthlyHeatingAccount(e.target.value)} 
                        onBlur={() => setMonthlyHeatingAccountError(validateNumberRange(monthlyHeatingAccount, 0))}
                        variant="outlined" 
                      />
                    </FormControl>
                    <FormControl>
                      <TextField 
                        type="number" 
                        id="monthly-water-account-input" 
                        label={t('dashboard.createListing.price.waterAccount.label')} 
                        value={monthlyWaterAccount} 
                        error={monthlyWaterAccountError}
                        onChange={e => setMonthlyWaterAccount(e.target.value)} 
                        onBlur={() => setMonthlyWaterAccountError(validateNumberRange(monthlyWaterAccount, 0))}
                        variant="outlined" 
                      />
                    </FormControl>
                    <FormControl>
                      <TextField 
                        type="number" 
                        id="deposit-input" 
                        label={t('dashboard.createListing.price.deposit.label')} 
                        value={deposit} 
                        error={depositError}
                        onChange={e => setDeposit(e.target.value)} 
                        onBlur={() => setDepositError(validateNumberRange(deposit, 0))}
                        variant="outlined" 
                      />
                    </FormControl>
                    <FormControl>
                      <TextField 
                        type="number" 
                        id="prepaid-rent-input" 
                        label={t('dashboard.createListing.price.prepaidRent.label')} 
                        value={prepaidRent} 
                        error={prepaidRentError}
                        onChange={e => setPrepaidRent(e.target.value)} 
                        onBlur={() => setPrepaidRentError(validateNumberRange(prepaidRent, 0))}
                        variant="outlined" 
                      />
                    </FormControl>
                  </Stack>
                </Accordion>
                <Accordion expanded={expanded === 'sizePanel'} onChange={handlePanelChange('sizePanel')} sx={{ width: '100%' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="size-panel-bh-content"
                    id="size-panel-bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      {t('dashboard.createListing.size.title')}
                    </Typography>
                  </AccordionSummary>
                  <Stack spacing={2} sx={{
                      mx: 5,
                      marginBottom: 5 
                    }}
                  >
                    <FormControl>
                      <TextField 
                        type="number" 
                        id="size-input" 
                        label={t('dashboard.createListing.size.size.label')} 
                        value={size} 
                        error={sizeError}
                        onChange={e => setSize(e.target.value)} 
                        onBlur={() => setSizeError(validateNumberRange(size, 0))}
                        variant="outlined" 
                      />
                    </FormControl>
                    <FormControl>
                      <TextField 
                        type="number" 
                        id="rooms-input" 
                        label={t('dashboard.createListing.size.rooms.label')} 
                        value={rooms} 
                        error={roomsError}
                        onChange={e => setRooms(e.target.value)} 
                        onBlur={() => setRoomsError(validateNumberRange(rooms, 0))}
                        variant="outlined" 
                      />
                    </FormControl>
                  </Stack>
                </Accordion>
                <Accordion expanded={expanded === 'otherPanel'} onChange={handlePanelChange('otherPanel')} sx={{ width: '100%' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="other-panel-bh-content"
                    id="other-panel-bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      {t('dashboard.createListing.other.title')}
                    </Typography>
                  </AccordionSummary>
                  <Stack spacing={1} sx={{
                      mx: 5,
                      marginBottom: 5 
                    }}
                  >
                    {Object.entries(checkboxInitObj).map(([key, value]) => (
                      <FormControlLabel control={<Checkbox checked={value['value']} onChange={(e) => {handleOtherChange(key, e)}} id={`${key}-checkbox`} />} label={value['label']} />
                    ))}
                    {/* {Object.entries(checkboxInitObj).map(([key, value]) => (
                      <FormControlLabel control={<Checkbox id={`${key}-checkbox`} />} label={value} />
                    ))} */}
                  </Stack>
                </Accordion>
                <Accordion expanded={expanded === 'imagesPanel'} onChange={handlePanelChange('imagesPanel')} sx={{ width: '100%' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="images-panel-bh-content"
                    id="images-panel-bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      {t('dashboard.createListing.images.title')}
                    </Typography>
                  </AccordionSummary>
                  <Stack spacing={1} sx={{
                      mx: 5,
                      marginBottom: 5 
                    }}
                  >
                    <Button variant="contained" color="primary" component="label">
                      {t('dashboard.createListing.images.images.button.label')}
                      <input type="file" onChange={(event) => setImages(Array.from(event.target.files))} hidden multiple />
                    </Button>
                    {images.length > 0 ? images.map((image, index) => (
                      <Typography>{index}. {image.name}</Typography>
                    )) : <Typography>{t('dashboard.createListing.images.images.notUploaded.label')}</Typography>}
                    <Button variant="contained" color="primary" component="label">
                      {t('dashboard.createListing.images.floorPlan.button.label')}
                      <input type="file" onChange={(event) => setFloorPlan(event.target.files[0])} hidden />
                    </Button>
                    {floorPlan !== null ? <Typography>{floorPlan.name}</Typography> : <Typography>{t('dashboard.createListing.images.floorPlan.notUploaded.label')}</Typography>}
                  </Stack>
                </Accordion>
                <Button
                  fullWidth
                  type='submit'
                  variant="contained"
                  sx={{ mt: 3, mb: 4 }}
                >
                  {t('dashboard.createListing.createButton.label')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grow>
      </Box>
      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {t('dashboard.createListing.success.alert')}
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {t('dashboard.createListing.error.alert')}
        </Alert>
      </Snackbar>
    </Box>
  );
}